<template>
<span class="is-flex is-align-items-center">
    <icon class="has-text-grey" v-if="withIcon" v-bind="{ icon }"/>
    <span v-if="isLocation" v-tooltip="'This checklist was perfromed against a location'">
        {{ inspectable.name }}
    </span>
    <span v-else v-tooltip="'This checklist was performed against a piece of equipment'">
        {{ inspectable.name }}
    </span>
</span>
</template>
<script>
export default {
    
    props: {
        type: String,
        withIcon: Boolean,
        inspectable: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        isLocation() {
            return this.type === 'location'
        },
        icon() {
            if(this.isLocation) {
                return 'home'
            }
            return 'qrcode'
        }
    }
}
</script>